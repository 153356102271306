.contentIcon {
    width: 20px;
    height: 20px;
}

.contentText {
    font-size: small;
}

.PlanContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.planTitle {
    color: black;
}

.planPrice {
    color: black;
    font-size: 3em;
    font-weight: bold;
    margin-top: -15px;
}

.planOldPrice {
    text-decoration: line-through;
    color: gray;
    width: 100%;
    font-size: 1.2em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    margin-top: -40px;
}

.paymentPlan {
    margin-top: -55px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: medium;
    color: black;
}

.planInfo {
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    font-size: medium;
    color: black;
}

.planSubInfo {
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    font-size: medium;
}

.planContentContainer {
    display: flex;
    height: 200px;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
}

.PlanContent {
    height: 30px;
    text-align: left;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentText {
    margin-top: auto;
    margin-left: 10px;
}