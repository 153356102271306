#register {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.form {
    background-color: #FBFCFE;
    border-radius: 20px;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    width: 100%;
    box-shadow: -4px -4px 10px #e6eef4, 4px 4px 10px #0000000d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .title {
    color: black;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  .subtitle {
    color: black;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }

  
  .ic1 {
    margin-top: 40px;
  }
  
  .ic2 {
    margin-top: 30px;
  }
  
  .input {
    height: 40px;
    width: 100%;
    font: 400 0.9375rem/1.5rem Roboto,sans-serif;
    color: #1d2026;
    background-color: #f1f5fd;
    border: 1.5px solid transparent;
    border-radius: 0.625rem;
    padding: 16px 22px;
    outline: none;
    box-sizing: border-box;
  }

  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }

  #register .inputContainer {
    height: 30px;
  }
  
  .placeholder {
    color: #65657b;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .input:not(:placeholder-shown) ~ .placeholder {
    color: #808097;
  }
  
  .input:focus ~ .placeholder {
    color: #dc2f55;
  }
  
  .submit {
    background: linear-gradient(90deg,#3371d9 0%,#66b3ff 100%);
    display: inline-block;
    position: relative;
    min-width: 9em;
    padding: 0.9375rem 2rem;
    overflow: hidden;
    border: none;
    border-radius: 0.625rem;
    text-align: center;
    font: 500 0.875rem/1rem Roboto,sans-serif;
    letter-spacing: 1.25px;
    cursor: pointer;
    transition: .3s cubic-bezier(.075,.82,.165,1);
    color: white;
    width: 100%;
    margin-top: 20px;
}
  .submit:active {
    background-color: #06b;
  }
  
  .registerTab {
    cursor: pointer;
  }

  .loginTab {
    cursor: pointer;
  }

  .header {
    margin-top: -30px;
  }
  
  #register .topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    margin-top: -70px;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }

  #securitiyImg {
    width: 200px;
    height: 200px;
    margin-top: 40px;
  }