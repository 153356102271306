#aboutRegister #leftContainer1 {
    width: 49%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A0A0A0;
    padding-left: 300px;
}

#aboutRegister #rightContainer1 {
    width: 49%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 60px;
    padding-right: 300px;
}

#imgChip {
    width: 200px;
    height: 200px;
}

#aboutRegister {
    display: flex;
}

#aboutRegisterTitle {
    color: #fff;
}

#leftText {
    font-size: medium;
    line-height: 25px;
    width:400px;
    text-align: center;
}