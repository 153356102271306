#dashBoard {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 1rem;
    height: 100%;
    padding-top: 100px;
    margin-left: 250px;
    margin-right: 60px;
    box-sizing: border-box;
}

#dashBoard .item {
    min-height: 260px;
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
    padding: 30px;
    background-color: #FCFCFE;
    border-radius: 30px;
    box-sizing: border-box;   
    
}

#dashBoard .item1 {
    grid-column: span 3;
    grid-row: span 1;
}

.item2 {
    grid-row: span 2;
    grid-column: span 1;
    background: linear-gradient(180deg, #4172D2 0%, #74AFF7 100%);
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
    padding: 30px;
    border-radius: 30px;
    box-sizing: border-box;   
}

.item7 {
    grid-column: span 2;
    overflow: hidden;
    padding: 0px;
    min-height: 260px;
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
    background-color: #FCFCFE;
    border-radius: 30px;
    box-sizing: border-box; 
}

.item8 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background: url("https://hub.ultralytics.com/static/placeholders/promo-video.jpg");
}

#item7Image {
    height: 100%;
    width: 100%;
}

@media (max-width: 1200px) {

    .item {
        grid-column: span 4;
        grid-row: span 1;
        min-height: 260px;
        filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
        padding: 30px;
        background-color: #9d9c9c;
        border-radius: 30px;
        box-sizing: border-box;   
        background: #FCFCFE;
    }

    .item1 {
        grid-column: span 4;
        grid-row: span 1;
    }

    .item2 {
        grid-column: span 4;
        grid-row: span 1;
        background: linear-gradient(180deg, #4172D2 0%, #74AFF7 100%);
        filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
        padding: 30px;
        border-radius: 30px;
        box-sizing: border-box;   
    }

    .item7 {
        grid-column: span 4;
        grid-row: span 1;
    }

    .actionItem {
        display: flex;
        flex-direction: column;
        grid-column: span 4;
        grid-row: span 1;
        box-sizing: border-box;
    }
}

#sideMenu {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    width: 180px;
    height: calc(100vh - 60px);
    background: linear-gradient(180deg, #4172D2 0%, #74AFF7 100%);
    box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.25);
    top: 30px;
    border-radius: 30px;
    margin-left: 25px;   
}

.line {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#logoImg {
    width: 120px;
}