@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#icon {
    width: 20px;
    height: 20px;
}

#menuItem {
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

#menuItem:hover {
  background-color:#76B3F9 ;
}

.selected {
    background-color:#76B3F9 ; 
}

.link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    color: white;
    text-decoration: none;
    width: 100%;
    font-weight:500;
    font-family:Roboto ;
    font-size: small;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 15px;
    height: 100%;
}

#linkText {
    color: white;
    font-size: medium;
    text-align: left;    
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 10px;
}