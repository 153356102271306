.SubscriptionPicker {
    height: 55px;
    border: solid black 1px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    margin-left: 200px;
    width: 300px;
    margin-top: 140px;
}

.PlanSelection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SubscriptionPicker button {
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 53px;
    font-size: medium;
    background: none;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    margin: 2px;
}

.SubscriptionPicker .selectedPlanButton {
    background: linear-gradient(180deg, #4172D2 0%, #74AFF7 100%);
    color: white;
}

.planPicker {
    display: flex;
    height: 660px;
    align-items: center;
    justify-content: center;
    margin-left: 200px;
    margin-top: 30px;
}

.leftPlan {
    border-left: solid black 1px;
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 375px;
    height: 540px;
}

.rightPlan {
    border-right: solid black 1px;
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: solid black 1px;
    width: 375px;
    height: 540px;
}

.midPlan {
    background: linear-gradient(180deg, #4172D2 0%, #74AFF7 100%);
    color: white;
    width: 400px;
    height: 600px;
    border-radius: 5px;
}

.midPlan p {
    color: white;
}

p {
    font-size: medium;
    max-width: 300px;
}

.PlanSelection {
    margin-top: -100px;
}