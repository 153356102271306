#cameraEditContainer {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

#cameraEditContainer label{
    color: black;
}