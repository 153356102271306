.deleteIcon {
    z-index: 1;
    width: 25px;
    height: 25px;
    margin-left: auto;
    position: fixed;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.hidePoster {
    display: none;
}

.showPoster {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
    border-radius: 10px;
}