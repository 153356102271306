#detections {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.detectionsGrid {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    grid-template-rows: repeat(3, 180px);
    gap: 1rem;
    height: 100%;
    box-sizing: border-box;   
    padding-top: 120px;
    margin-left: 250px;
    margin-right: 60px;
}

.detectionImage {
    width: 320px;
    height: 180px;
    border-radius: 20px;
}