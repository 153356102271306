
.staticVidContainer {
    width: 100%;
    height: 100%;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    background-color: rgba(205,217, 222, 0.9);
}

.none {
    display: none;
}

#btnClose {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 20px;
    cursor: pointer;
}