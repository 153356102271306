#cameras {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-x: hidden;
}

#camerasContainer {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    grid-template-rows: repeat(3, 180px);
    gap: 1rem;
    height: 100%;
    box-sizing: border-box;   
    padding-top: 120px;
    margin-left: 250px;
    margin-right: 60px;
}

.camera {
    filter: drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.25));
    background-color: #9d9c9c;
    border-radius: 10px;
    box-sizing: border-box;   
    background: linear-gradient(135.15deg, #404040 1.31%, #4E525B 96.87%);    
    grid-column: span 1;
    grid-row: span 1;
}

.hiddenCamera {
    position: absolute;
    display: none;
    opacity: 0; 
}


.editCameraContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(205,217, 222, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

#noCameraText {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    height: 100vh;
    pointer-events: none;
}