@font-face {
    font-family: SF ProBold;
    src: url('../../fonts/SFPRODISPLAYBOLD.OTF');
}

@font-face {
    font-family: SF Pro;
    src: url('../../fonts/SFPRODISPLAYREGULAR.OTF');
}

#homePage {
    height: 100vh;
}

#homePage h1 {
    font-family: 'SF ProBold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    background: linear-gradient(89.7deg, #6C6EA2 0.26%, #8060C4 105.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: start;
}

p {
    font-family: SF Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #A0A0A0;
    margin-bottom: 50px;
}

#header {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#homePage #leftContainer {
    width: 49%;
    justify-content: center;
    display: flex;
}

#homePage #rightContainer {
    width: 49%;
    height: 600px;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 60px;
}

#infoText {
    text-align: left;
}

.videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90.01deg, #303030 0.99%, #3A3E47 98.78%);
    box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

video {
    width: 600px;
    border-radius: 20px;
}

.headerText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 200px;
}

#lineImg {
    width: 500px;
    margin-top: -20px;
}

.bthDemo {
    background: linear-gradient(90.01deg, #303030 0.99%, #3A3E47 98.78%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 150px;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 350px;
}