.cameraUrlInput {
    border: 1px solid #237fa0;
    border-radius: 10px;
    width: 250px;
    height: 40px;
    padding-left: 20px;
    font-size: medium;
    background: none;
    font-weight: bold;
    color: #237fa0;
    cursor: pointer;
    filter: drop-shadow(30px,30px,30px,rgba(0,0,0,0.25));
    font-size: medium;
    visibility: visible;
    margin-right: 20px;
    box-sizing: border-box;
}

.cameraUrlInput::placeholder {
    color: #237fa0;
    font-size: small;
}

.addCameraForm {
    width: 500px;
    height: 50px;
    position: fixed;
    top: 60px;
    left: 220px;
}

.btnAddCamera {
    visibility: visible;
    border: 1px solid #237fa0;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    font-size: medium;
    background: none;
    font-weight: bold;
    color: #237fa0;
    cursor: pointer;
    filter: drop-shadow(30px,30px,30px,rgba(0,0,0,0.25));
}