@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.tabs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.tabText {
    font-style: normal;
    font-variant-caps: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-variant-alternates: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-weight: 500;
    font-stretch: normal;
    font-size: 0.9125rem;
    line-height: 1.5rem;
    font-family: Roboto, sans-serif;
    color: gray;
    margin: 10px;
}

.selectedText {
    color: black;
    position: relative;
}

.selectedText::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Adjust this value to control the space between text and underline */
    left: 0;
    width: 100%;
    height: 2px; /* Adjust this value to control the underline thickness */
    background-color: rgb(59 130 246 / .5); /* Change this to the desired underline color */
  }

hr {
    width: 150px;
    color: #8f929f;
    background-color: #8f929f;

}

.header {
    display: flex;
    color: #8f929f;
    margin-top: -50px;
}

.spText {
    color: #8f929f;
    margin: 5px;
}

.loginOptions {
    margin-top: -20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.authButton {
    width: 30px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-evenly;
    width: 100%;
}

label {
    width: 100%;
    text-align: left;
    color: #8f929f;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-variant-alternates: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 0.8125rem;

    font-family: Roboto, sans-serif;
}

.pInput {
    margin-top: -80px;
}

.eInput {
    margin-top: -60px;
}

.submitButton {
    background: linear-gradient(90deg,#3371d9 0%,#66b3ff 100%);
    display: inline-block;
    position: relative;
    min-width: 9em;
    padding: 0.9375rem 2rem;
    overflow: hidden;
    border: none;
    border-radius: 0.625rem;
    text-align: center;
    font: 500 0.875rem/1rem Roboto,sans-serif;
    letter-spacing: 1.25px;
    cursor: pointer;
    transition: .3s cubic-bezier(.075,.82,.165,1);
    color: white;
    width: 100%;
    margin-top: -40px;
}

#login {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
}

#login .topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    margin-top: -150px;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }

  #securitiyImg {
    width: 200px;
    height: 200px;
    margin-top: 40px;
  }