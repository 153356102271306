#support {
    width: 100vw;
    height: 100vh;
    position: relative;
}

#support .gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 1rem;
    height: 100%;
    padding-top: 100px;
    margin-left: 250px;
    margin-right: 60px;
    box-sizing: border-box;
}