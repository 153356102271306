#profileImg {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    border: 5px solid white;
}

#profileTitle {
    color: white;
    font-size: medium;
}

#profileIcon {
    width: 20px;
    height: 20px;
}

#email {
    font-size: medium;
    margin-top: -10px;
    color: white;
}